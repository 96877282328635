import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { posthog } from 'posthog-js';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import 'css/index.css';

import { App } from 'app/App';

import type { TagManagerArgs } from 'react-gtm-module';

const tagManagerArgs: TagManagerArgs = {
  gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  enabled: import.meta.env.VITE_SENTRY_DSN !== undefined,
  environment: import.meta.env.PROD ? 'production' : 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  release: import.meta.env.VITE_RELEASE_VERSION,
  beforeSend(event) {
    if (
      event.exception &&
      import.meta.env.PROD &&
      import.meta.env.VITE_ENV !== 'production'
    ) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  tracesSampleRate: 1.0,
});

if (import.meta.env.VITE_POSTHOG_API_KEY) {
  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: 'https://app.posthog.com',
  });
}

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(<App />);
